<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div
      @mousedown.stop
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <video v-if="video.videoUrl" controls autoplay>
            <source :src="video.videoUrl" type="video/mp4">
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div class="modal-footer">
          <button
            @click="close"
            type="button"
            class="btn btn-primary"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseVideoModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    video: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    title() {
      if (this.index < 0) return 'Clip';
      return `Clip ${this.index + 1}`;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  display: block;
  max-height: 60vh;;
  max-width: 100%;
  width: 100%;
  height: auto;
}
</style>
